<template>
  <!-- location START -->
  <div class="account-branch-finder-location">
    <!-- map START -->
    <div id="map" class="map"></div>
    <!-- map END -->

    <!-- location START -->
    <div class="location" v-if="location">
      <!-- open:door START -->
      <router-link
      class="open-door"
        :to="'/qr-code'"
      >
        <span v-html="$t('locations.location.opendoor')"></span>
      </router-link>
      <!-- open:door END -->

      <!-- location:accordion START -->
      <div class="accordion">
        <!-- location:accordion:opening-hours START -->
        <location-accordion-item>
          <!-- location:accordion:header START -->
          <template v-slot:location-header>
            <div
              :class="[
                'icon',
                { 'opened' : accordionOpening }
              ]"
              v-on:click="accordionOpening = !accordionOpening"
            ></div>

            <div
              class="headline"
              v-html="$t('location.openinghours')"
            ></div>
            <div class="status-holder">
              <span
                class="status open"
                v-if="boxOpened.isOpen"
                v-html="`<strong>${$t('location.open')}</strong>`"
              ></span>
              <span
                class="status closed"
                v-else
                v-html="`<strong>${$t('location.closed')}</strong><br>${boxOpened.status}`"
              ></span>
              <span
                class="opening-hours"
                v-if="location[0].status && today && location[0].openingHours && !accordionOpening"
                v-html="`${$t('date.today')}: ${today.hours}`"
              ></span>
            </div>
          </template>
          <!-- location:accordion:header END -->

          <!-- location:accordion:content START -->

          <template
            v-slot:location-content
            v-if="accordionOpening"
          >
            <div
              class="hours"
              v-for="(day, dayIndex) in location[0].openingHours"
              :key="`weekday-day-${dayIndex}`"
            >
              <span
                v-html="`<span class='weekday-name'>${weekdayName(day.weekday)}</span>:
                ${day.timeFrom} - ${day.timeTo}`"
              ></span>
            </div>
          </template>
          <!-- location:accordion:content END -->
        </location-accordion-item>
        <!-- location:accordion:opening-hours END -->

        <!-- location:accordion:address START -->
        <location-accordion-item>
          <!-- location:accordion:header START -->
          <template v-slot:location-header>
            <div
              class="headline"
              v-html="$t('location.address')"
            ></div>
            <div class="address-holder">
              <div
                class="address"
                v-if="location[0].street"
                v-html="`${location[0].street} ${location[0].houseNumber}`"
              ></div>
              <div
                class="place"
                v-if="location[0].city"
              >
                <span v-html="`${location[0].zip} ${location[0].city}`"></span>
                <span
                  v-if="finalDistance"
                  v-html="` (${finalDistance} km)`"
                ></span>
                <a
                  :href="`https://www.google.at/maps/dir/${currentLocationLat},${currentLocationLng}/${location[0].lat},${location[0].lng}/`"
                  target="_blank"
                  rel="noopener noreferrer"
                  v-html="$t('location.navigate')"
                ></a>
              </div>
            </div>
          </template>
          <!-- location:accordion:header END -->
        </location-accordion-item>
        <!-- location:accordion:address END -->

        <!-- location:accordion:actions START -->
        <location-accordion-item v-if="location[0].actions">
          <!-- location:accordion:header START -->
          <template v-slot:location-header>
            <div
              :class="[
                'icon',
                { 'opened' : accordionSpecials }
              ]"
              v-on:click="accordionSpecials = !accordionSpecials"
            ></div>

            <div
              class="headline"
              v-html="$t('location.specials')"
            ></div>
            <div
              class="actions-holder"
              v-if="location[0].actions"
              v-html="location[0].actions"
            ></div>
          </template>
          <!-- location:accordion:header END -->

          <!-- location:accordion:content START -->
          <template
            v-slot:location-content
            v-if="accordionSpecials && location[0].allActions"
          >
            <div
              class="actions"
              v-for="(action, actionIndex) in location[0].allActions"
              :key="`actions-action-${actionIndex}`"
            >
              <span
                v-html="`
                  <h4>${action.headline}</h4>
                  <p>${action.description}</p>
                `"
              ></span>
            </div>
          </template>
          <!-- location:accordion:content END -->
        </location-accordion-item>
        <!-- location:accordion:actions END -->
      </div>
      <!-- location:accordion END -->

      <!-- {{location}} -->
    </div>
    <!-- location END -->
  </div>
  <!-- location END -->
</template>

<script>
import { Geolocation } from '@capacitor/geolocation';
import titleMixin from '@/mixins/titleMixin';
import ICONS from '../../utils/icons';

let {
  google,
} = window;

const {
  iLocationGoogleMarker,
} = ICONS;

export default {
  name: 'branch-finder-location',
  mixins: [titleMixin],
  components: {
    LocationAccordionItem: () => import('../../components/branch-finder/location/AccordionItem.vue'),
  },
  data() {
    return {
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: ' ',

      /**
       * box (info)
       */
      boxOpened: {
        isOpen: true,
        status: '',
      },

      /**
       * Google Maps
       */
      finalDistance: 0,
      options: {
        zoom: 13,
        center: new google.maps.LatLng(47.850193, 13.196229),
        disableDefaultUI: true,
        styles: [
          { stylers: [{ hue: '#7FB0B2' }, { saturation: 16 }] },
          { featureType: 'water', stylers: [{ color: '#F7F7F7' }] },
          { featureType: 'all', elementType: 'labels', stylers: [{ visibility: 'off' }] },
          { featureType: 'administrative', elementType: 'labels', stylers: [{ visibility: 'on' }] },
          { featureType: 'road', elementType: 'geometry', stylers: [{ visibility: 'on' }, { color: '#FFFFFF' }] },
          { featureType: 'transit', elementType: 'all', stylers: [{ visibility: 'off' }, { color: '#FFFFFF' }] },
          { featureType: 'landscape', elementType: 'all', stylers: [{ hue: '#7FB0B2' }, { saturation: 0, lightness: -20 }] },
        ],
      },
      map: '',

      /**
       * Location
       */
      location: null,
      today: null,
      currentLocation: null,
      currentLocationLat: null,
      currentLocationLng: null,
      accordionOpening: false,
      accordionSpecials: false,
      locationID: Number(this.$route.params.id) || '',
    };
  },
  async created() {
    this.locations = await this.FETCH_LOCATIONS();
    if (this.locations) {
      this.location = this.locations
        .filter((location) => location.id === this.locationID);

      // update page title
      this.addPageTitle(`${this.$t('footer.branch.second')} ${this.location[0].city}`);

      // reinit google
      google = window.google;

      // init map
      this.map = new google.maps.Map(
        document.getElementById('map'),
        this.options,
      );

      // center map to the anybox location
      this.map.setCenter({
        lat: Number(this.location[0].lat),
        lng: Number(this.location[0].lng),
      });

      // add marker to the anybox location
      this.addMarker();

      // check status of box
      this.isBoxOpen();

      // get distance between current location & anybox location
      this.getCurrentLocation();
    }
  },
  methods: {
    async FETCH_LOCATIONS() {
      await this.$store.dispatch('setLocations');
      return this.$store.getters.locations;
    },

    addMarker() {
      const marker = new google.maps.Marker({
        icon: {
          url: iLocationGoogleMarker.defaultUrl,
          size: new google.maps.Size(24, 29),
          scaledSize: new google.maps.Size(24, 29),
        },
        position: {
          lat: Number(this.location[0].lat),
          lng: Number(this.location[0].lng),
        },
      });
      marker.setMap(this.map);
    },

    async getCurrentLocation() {
      const coordinates = await Geolocation.getCurrentPosition();

      if (coordinates) {
        const pos = {
          lat: coordinates.coords.latitude,
          lng: coordinates.coords.longitude,
        };
        const convertedCenter = new google.maps.LatLng(
          parseFloat(pos.lat),
          parseFloat(pos.lng),
        );
        this.currentLocation = convertedCenter;
        this.currentLocationLat = coordinates.coords.latitude;
        this.currentLocationLng = coordinates.coords.longitude;

        this.getDistance();
      } else {
        console.log("Browser doesn't support Geolocation!");
      }
    },

    getDistance() {
      const self = this;
      const service = new google.maps.DistanceMatrixService();
      const currentMarker = self.location;
      const convertedCenter = new google.maps.LatLng(
        parseFloat(currentMarker[0].lat),
        parseFloat(currentMarker[0].lng),
      );
      const convertedCurrentLocation = JSON.parse(JSON.stringify(self.currentLocation));

      if (convertedCurrentLocation && convertedCenter) {
        service.getDistanceMatrix({
          origins: [convertedCurrentLocation],
          destinations: [convertedCenter],
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false,
        }, (response) => {
          const distanceValue = (response.rows[0].elements[0].distance.value / 1000)
            .toFixed(1)
            .replace('.0', '')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
          self.finalDistance = distanceValue;
        });
      }
    },

    weekdayName(weekdayNumber) {
      const days = [
        {
          weekday: 1,
          name: `${this.$t('date.sunday')}`,
        },
        {
          weekday: 2,
          name: `${this.$t('date.monday')}`,
        },
        {
          weekday: 3,
          name: `${this.$t('date.tuesday')}`,
        },
        {
          weekday: 4,
          name: `${this.$t('date.wednesday')}`,
        },
        {
          weekday: 5,
          name: `${this.$t('date.thursday')}`,
        },
        {
          weekday: 6,
          name: `${this.$t('date.friday')}`,
        },
        {
          weekday: 7,
          name: `${this.$t('date.saturday')}`,
        },
      ];
      return days.filter((day) => weekdayNumber === day.weekday).map((day) => day.name);
    },

    isBoxOpen() {
      // check on init if opened or closed
      this.$http.get(`${process.env.VUE_APP_BASE_URL}box/isOpen`,
        {
          params: {
            boxID: this.locationID,
          },
          headers: {
            'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          },
        })
        .then((response) => {
          if (response.data) {
            const { isOpen, status } = response.data;

            // update notification
            this.boxOpened = {
              isOpen,
              status,
            };
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.errorStatus = 'Error: Network Error';
          } else {
            // Close loading
            this.errorStatus = error.response.data;
            this.serverErrorMessages = this.errorStatus.errors;

            this.serverErrorMessages.forEach((message) => {
              this.boxOpened = {
                isOpen: false,
                status: `${this.$t(`error.${message.code}`)}`,
              };
            });
          }
        });
    },
  },
  watch: {
    finalDistance(val) {
      return val;
    },
  },
};
</script>
